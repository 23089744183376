<template>
  <div class="chatbot-config">
    <KTCodePreview
      v-bind:title="`Danh sách cấu hình chatbot ${botSettingType}`"
    >
      <!-- button create new -->
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-0">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
              @click="upsert"
            >
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
            </button>
          </div>
        </div>
      </template>
      <!-- reason configuration table -->
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0">
                <b-input
                  v-model.trim="searchName"
                  placeholder="Nhập tên"
                  size="sm"
                  @keyup.enter="onFilter()"
                  autofocus
                ></b-input>
              </b-col>
              <b-col>
                <b-form-group>
                  <b-form-select
                    v-model="searchEvent"
                    :options="eventOptions"
                    size="sm"
                  >
                  <b-form-select-option value="">-- Lọc theo sự kiện --</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="12">
            <b-table :items="items" :fields="fields" bordered>
              <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </b-dropdown-item>
                  <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="paging">
          <div class="total">
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </div>
          <div class="paging-nav">
            <b-pagination-nav
              class="custom-pagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchConfigs"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </div>
        </div>
        <b-col></b-col>
        <b-col></b-col>

        <!-- Cấu hình chức vụ - số giờ công -->
        <div class="add-model">
          <b-modal ref="my-modal" hide-footer title="Thông tin cấu hình">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Nhập tên</label>
                  <b-input
                    v-model="botGroup.botGroupName"
                    placeholder="Nhập tên"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Cửa hàng</label>
                  <Autosuggest
                    :suggestions="stores"
                    :model="botGroup.selectedStore.name"
                    :placeholder="'cửa hàng hoặc để trống nếu áp dụng tất cả'"
                    :limit="10"
                    @change="onInputStore"
                    @select="onSelectStore($event)"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Chat ID</label>
                  <b-input
                    v-model="botGroup.chatId"
                    placeholder="Nhập chat Id"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Webhook URL</label>
                  <b-input
                    v-model.trim="botGroup.webhookUrl"
                    placeholder="Nhập webhook url"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Sự kiện</label>
                  <b-form-select
                    v-model="botGroup.selectedEventId"
                    :options="eventOptions"
                    placeholder="Nhập sự kiện"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Trạng thái</label>
                  <b-form-select
                    v-model="botGroup.selectedStatus"
                    :options="statusOptions"
                    placeholder="Nhập trạng thái"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              class="mt-2"
              variant="btn btn-primary"
              block
              @click="onSaveModal"
              >Lưu</b-button
            >

            <b-button
              class="mt-3"
              variant="btn btn-outline-secondary"
              block
              @click="hideModal"
              >Đóng</b-button
            >
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.paging {
  display: flex;
  justify-content: space-between;
}
.form-group label {
  font-weight: 500;
}
.submit-config button {
  font-weight: 600;
  width: 70px;
}
.submit-config button:nth-child(2) {
  margin-left: 10px;
}
.transport-config-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import cloneDeep from 'lodash/cloneDeep';
import { BOT_SETTING_ID, BOT_SETTING_TYPE } from '../../../utils/enum';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
  },
  data() {
    return {
      statusOptions: [
        { value: 1, text: 'Hoạt động' },
        { value: 2, text: 'Ngừng Hoạt động' },
      ],
      eventOptions: [],
      stores: [],
      storeOptions: [],
      totalPages: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      items: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên cấu hình',
          thStyle: { color: '#181c32', width: '240px' },
        },
        {
          key: 'botName',
          label: 'Tên bot',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'chatId',
          label: 'Chat ID',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'eventName',
          label: 'Sự kiện',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      limit: 10,
      onSaving: false,
      searchName: '',
      searchEvent: '',
      botGroup: {
        id: null,
        botGroupName: '',
        selectedStore: {
          id: null,
          name: '',
        },
        chatId: null,
        selectedEventId: 1,
        selectedStatus: 1,
        webhookUrl: '',
      },
      originalBotGroup: {},
      title: 'Cấu hình chat chatbot telegram',
      botSettingType: BOT_SETTING_TYPE.TELEGRAM,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.setBotSettingType(to.path);
        this.fetchConfigs();
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: this.title,
            route: `Danh sách cấu hình chatbot ${this.botSettingType}`,
          },
        ]);
      }
    },
  },
  created() {
    this.setBotSettingType(this.$route.path);
    this.fetchStore();
    this.fetchConfigs();
    this.fetchEventOption();
    this.originalBotGroup = cloneDeep(this.botGroup);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.title,
        route: `Danh sách cấu hình chatbot ${this.botSettingType}`,
      },
    ]);
  },
  methods: {
    setBotSettingType(path = {}) {
      if (path.includes(BOT_SETTING_TYPE.LARK)) {
        this.botSettingType = BOT_SETTING_TYPE.LARK;
      } else {
        this.botSettingType = BOT_SETTING_TYPE.TELEGRAM;
      }
      this.title = `Cấu hình chatbot ${this.botSettingType}`;
    },
    async fetchEventOption() {
      try {
        const {
          data: { data },
        } = await ApiService.get('chatbot-config/event');
        this.eventOptions = data;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    fetchStore: function () {
      this.stores = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        const allStore = {
          id: -1,
          name: 'Tất cả',
        };
        if (stores.length) stores.unshift(allStore);
        this.stores = this.storeOptions = stores;
      });
    },
    onInputStore(text = '') {
      this.botGroup.selectedStore.name = text;
      const filteredData = this.storeOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.stores = [...filteredData];
    },
    onSelectStore(option) {
      this.botGroup.selectedStore.id = option.item.id;
      this.botGroup.selectedStore.name = option.item.name;
    },
    convertPrice,
    addTransportConfig() {
      const transportDTO = {
        workingHourFrom: 0,
        workingHourTo: 0,
        percentage: 0,
      };
      this.transportConfigItems.push(transportDTO);
    },
    async upsertConfig() {
      const botGroup = {
        id: this.botGroup.id,
        name: this.botGroup.botGroupName,
        groupId: this.botGroup.chatId,
        storeId: this.botGroup.selectedStore.id || -1,
        eventId: this.botGroup.selectedEventId,
        status: this.botGroup.selectedStatus,
        webhookUrl: this.botGroup.webhookUrl,
      };
      const botSetting = {
        [BOT_SETTING_TYPE.TELEGRAM]: BOT_SETTING_ID.TELEGRAM,
        [BOT_SETTING_TYPE.LARK]: BOT_SETTING_ID.LARK,
      };
      botGroup.botSettingId = botSetting[this.botSettingType];
      if (!this.validateUpsertData(botGroup)) {
        return;
      }
      if (this.onSaving) return;

      this.onSaving = true;
      ApiService.post('chatbot-config', botGroup)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.fetchConfigs();
            this.hideModal();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
          this.onSaving = false;
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.onSaving = false;
          }
        });
    },
    validateUpsertData(payload) {
      if (payload.webhookUrl) {
        const regex =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
        const isValidUrl = regex.test(payload.webhookUrl);

        if (!isValidUrl) {
          makeToastFaile('webhookUrl không hợp lệ !');
          return false;
        }
      }
      if (!payload.botSettingId) {
        makeToastFaile('botSettingId không hợp lệ !');
        return false;
      }
      return true;
    },
    onSaveTransportConfig() {
      this.upsertTransportConfig();
      this.hideTransportConfigModal();
    },
    configTransport() {
      this.showTransportConfigModal();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        path: this.$route.path,
      });
      this.fetchConfigs();
    },
    removeAccents,
    upsert() {
      this.selectedJobTitle = {
        id: null,
        name: '',
        standardWorkingHour: 0,
        standardWorkingDay: 0,
        transportationAllowanceAmount: 0,
      };
      this.showModal();
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.botGroup = cloneDeep(this.originalBotGroup);
      this.$refs['my-modal'].hide();
    },
    onSaveModal() {
      this.upsertConfig();
    },
    fetchConfigs: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const param = {
        page: this.page,
        pageSize: 10,
        name: this.searchName,
        event: this.searchEvent,
        botSettingType: this.botSettingType,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('chatbot-config', paramQuery).then((response) => {
        const dataResponse = response.data.data;
        this.items = [];
        this.totalPages =
          dataResponse.totalPages > 0 ? dataResponse.totalPages : 1;
        this.totalItems = dataResponse.totalItems;
        this.currentPage = dataResponse.currentPage;
        this.items = [...dataResponse.result];
      });
    },
    deleteItem: async function (item) {
      ApiService.delete('chatbot-config' + '/' + item)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            this.fetchConfigs();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cấu hình!',
        text: 'Bạn có chắc muốn xóa cấu hình này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.value) {
            this.deleteItem(item);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          this.makeToastFaile(message);
        });
    },
    editItem: function (item) {
      this.botGroup.id = item.id;
      this.botGroup.botGroupName = item.name;
      this.botGroup.selectedStore.id = item.storeId;
      this.botGroup.selectedStore.name = item.storeName;
      this.botGroup.chatId = item.chatId;
      this.botGroup.selectedStatus = item.status;
      this.botGroup.webhookUrl = item.webhookUrl;
      this.botGroup.selectedEventId = item.eventId;
      this.showModal();
    },
  },
};
</script>